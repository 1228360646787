<template>
  <v-btn color="success py-6" block @click="abriChat()">
    <v-icon class="mx-5" x-large>mdi-whatsapp</v-icon>
    Chatea con nosotros
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      numero: "51972530303",
    };
  },
  methods: {
    abriChat() {
      let text = `https://api.whatsapp.com/send/?phone=${this.numero}&text=Hola, ACO. Me gustaría contactar con alguien&type=phone_number&app_absent=0`;
      window.open(text);
    },
  },
};
</script>
