<template class="formulario">
  <v-row>
    <v-col cols="12">
      <center><b> 2. CONFIGURACIÓN DE PROVEEDORES </b></center>
    </v-col>
    <v-row v-if="!subirProveedor" class="mx-1 my-1">
      <v-col cols="12">
        <center>
          <v-icon size="60" color="red">mdi-alert</v-icon>
        </center>
      </v-col>
      <v-col lg="6" md="6" sm="12" cols="12">
        <div class="opcion">
          <div class="opcionText">
            <p><b>OPCIÓN A: CARGAR PROVEEDORES AHORA</b></p>
            <p>
              Esta opción podrás cargar tus proveedores en este momento para que
              el sistema pueda usar las tarifas correctamente y calcular los
              precios con precisión en el módulo de PRICING
            </p>
          </div>
          <v-btn
            class="my-1"
            block
            color="#4256BE"
            dark
            @click="subirProveedor = !subirProveedor"
          >
            Cargar proveedores ahora
          </v-btn>
        </div>
      </v-col>
      <v-col lg="6" md="6" sm="12" cols="12">
        <div class="opcion">
          <div class="opcionText">
            <p><b>OPCIÓN A: USAR PROVEEDORES PROVISIONALES</b></p>
            <p>
              Esta opción el sistema usará inmediatamente proveedores
              provisionales con tarifas pre definidas, de esta forma podrás
              explorar todas las funcionalidades del módulo de PRICING y
              configurar tus proveedores cuando lo necesites.
            </p>
          </div>
          <v-btn
            class="my-1"
            block
            color="#7E7E7E"
            dark
            @click="$emit('omitir')"
          >
            Usar PROVEEDORES provisionales
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-col cols="12" v-else class="my-1">
      <v-row>
        <v-col cols="8">
          <v-icon size="40" color="red">mdi-alert</v-icon> Encuentra los pasos a
          seguir en el tutorial adjunto
        </v-col>
        <v-col cols="4">
          <v-btn color="#5E9AD6" dark small @click="dialog = true">
            Ver Tutorial
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              LISTADO DE PROVEEDORES <v-spacer></v-spacer>
              <v-btn
                color="#7d8288"
                dark
                @click="descargarFormato()"
                x-small
                class="mx-1"
              >
                Descargar Plantilla
              </v-btn>
              <v-btn
                color="#062c52"
                dark
                x-small
                class="mx-1"
                @click="openFileInput()"
              >
                Cargar Proveedores
              </v-btn>
              <v-file-input
                v-model="files"
                id="fileInput"
                style="display: none"
                @change="handleFileChange"
                accept=".xlsx, .xls"
              />
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="$store.state.configEmpresa.lstProveedor"
              pagination.sync="pagination"
              item-key="index"
              class="elevation-5"
            >
              <template v-slot:[`item.action`]="{ item, index }">
                <v-btn icon color="red" @click="eliminar(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" style="align-items: center; text-align: center">
      <v-btn
        class="mx-10 mt-1 mb-10"
        color="#7E7E7E"
        @click="$emit('omitir')"
        dark
      >
        Omitir
      </v-btn>
      <v-btn
        class="mx-10 mt-1 mb-10"
        @click="continuar()"
        :disabled="!subirProveedor"
        color="#4256BE"
        :dark="subirProveedor"
      >
        Guardar y Continuar
      </v-btn>
    </v-col>
    <v-dialog
      v-model="dialog"
      v-if="dialog"
      scrollable
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title primary-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = !dialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <iframe
            width="800"
            height="400"
            src="https://www.youtube.com/embed/Vk07cXLM2vo"
            title="Tutorial Carga Proveedor"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import readXlsxFile from "read-excel-file";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      dialog: false,
      subirProveedor: false,
      files: [],
      headers: [
        { text: "Acción", value: "action" },
        { text: "TAX ID / RUC /  VAT / RIF", value: "nro_documento" },
        { text: "Razón social/Nombre Comercial", value: "nombre_comercial" },
        { text: "Email", value: "email" },
        { text: "Persona Contacto 1", value: "contacto_1" },
        { text: "Teléfono Contacto 1", value: "contacto_telf_1" },
        { text: "Persona Contacto 2", value: "contacto_2" },
        { text: "Teléfono Contacto 2", value: "contacto_telf_2" },
        { text: "Dirección", value: "direccion" },
        { text: "Teléfono adicional 1", value: "telefono_1" },
        { text: "Teléfono adicional 2", value: "telefono_2" },
      ],
      mostrarGuardar: false,
      index_nombre_comercial: 0,
      index_nro_documento: 1,
      index_contacto_1: 2,
      index_contacto_telf_1: 3,
      index_contacto_2: 4,
      index_contacto_telf_2: 5,
      index_direccion: 6,
      index_telefono_1: 7,
      index_telefono_2: 8,
      index_email: 9,
      loading: false,
      subconjuntos: [],
    };
  },
  methods: {
    ...mapActions(["actualizarDatoCMProveedor"]),
    omitir() {
      this.$store.state.enterprises.datoProveedorFlag = false;
      this.$store.state.enterprises.datoClienteFlag = true;
    },
    scrollToSubirCostos() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async continuar() {
      this.scrollToSubirCostos();
      this.$store.state.dialogProcessing = true;
      this.dividirEnSubconjuntos();
      for (const subconjunto of this.dividirEnSubconjuntos()) {
        await this.actualizarDatoCMProveedor(subconjunto);
      }
      Swal.fire({
        icon: "success",
        text: "Registro Correcto",
        showCancelButton: false,
        confirmButtonText: "Ok",
        allowEnterKey: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$emit("continuarClientes");
        }
      });

      // this.$emit("continuarClientes");
      this.$store.state.dialogProcessing = false;
    },
    descargarFormato() {
      window.open(
        "https://api-general.qreport.site/uploads/Formato Proveedor.xlsx",
        "_blank"
      );
    },
    dividirEnSubconjuntos() {
      const SUBCONJUNTO_TAMANO = 100;
      const subconjuntos = [];
      for (
        let i = 0;
        i < this.$store.state.configEmpresa.lstProveedor.length;
        i += SUBCONJUNTO_TAMANO
      ) {
        subconjuntos.push(
          this.$store.state.configEmpresa.lstProveedor.slice(
            i,
            i + SUBCONJUNTO_TAMANO
          )
        );
      }
      return subconjuntos;
    },
    openFileInput() {
      let fileInput = document.getElementById("fileInput");
      if (fileInput) {
        this.files = [];
        fileInput.click();
      }
      //   document.getElementById("fileInput").click();
    },
    handleFileChange() {
      this.$store.state.configEmpresa.lstProveedor = [];
      readXlsxFile(this.files).then((rows) => {
        this.procesarDatosProveedor(rows);
      });
      this.mostrarGuardar = true;
      this.files = [];
    },
    procesarDatosProveedor(rows) {
      for (let index = 0; index < rows[0].length; index++) {
        if (rows[0][index] == "nombre_comercial") {
          this.index_nombre_comercial = index;
        }
        if (rows[0][index] == "nro_documento") {
          this.index_nro_documento = index;
        }
        if (rows[0][index] == "contacto_1") {
          this.index_contacto_1 = index;
        }
        if (rows[0][index] == "contacto_telf_1") {
          this.index_contacto_telf_1 = index;
        }
        if (rows[0][index] == "contacto_2") {
          this.index_contacto_2 = index;
        }
        if (rows[0][index] == "contacto_telf_2") {
          this.index_contacto_telf_2 = index;
        }
        if (rows[0][index] == "direccion") {
          this.index_direccion = index;
        }
        if (rows[0][index] == "telefono_1") {
          this.index_telefono_1 = index;
        }
        if (rows[0][index] == "telefono_2") {
          this.index_telefono_2 = index;
        }

        if (rows[0][index] == "email") {
          this.index_email = index;
        }
      }

      for (let index = 1; index < rows.length; index++) {
        this.$store.state.configEmpresa.lstProveedor.push({
          nombre_comercial: rows[index][this.index_nombre_comercial],
          nro_documento: rows[index][this.index_nro_documento],
          contacto_1: rows[index][this.index_contacto_1],
          contacto_telf_1: rows[index][this.index_contacto_telf_1],
          contacto_2: rows[index][this.index_contacto_2],
          contacto_telf_2: rows[index][this.index_contacto_telf_2],
          direccion: rows[index][this.index_direccion],
          telefono_1: rows[index][this.index_telefono_1],
          telefono_2: rows[index][this.index_telefono_2],
          email: rows[index][this.index_email],
        });
      }
      this.files = [];
    },
    eliminar(index) {
      this.$store.state.configEmpresa.lstProveedor.splice(index, 1);
    },
  },
  mounted() {
    // console.log("recargo");
    this.ids_modality = this.$store.state.pricing.listModality.map(
      (element) => {
        return element.id;
      }
    );
    this.ids_shipment = this.$store.state.pricing.listShipment.map(
      (element) => {
        return element.id;
      }
    );
    this.id_modality = this.ids_modality[0];
    this.id_shipment = this.ids_shipment[0];
  },
};
</script>

<style scoped>
.opcion {
  border: 1px solid #4256be;
  margin: 1%;
  padding: 5%;
  border-radius: 25px;
}
.opcionText {
  /* border: 1px solid red; */
  /* margin: 5%; */
  text-align: justify;
  height: 150px;
}
@media (max-width: 1000px) {
  .formulario {
    /* margin: 0%; */
    /* width: 50px !important; */
    padding: 0 0 5% 5%;
  }
  .opcion {
    border: 1px solid #4256be;
    margin: 1%;
    padding: 5%;
    border-radius: 25px;
    max-width: 270px;
  }
  .opcionText {
    text-align: justify;
    height: 250px;
  }
}
</style>
